<!--
  只有select框
-->
<template>
  <div ref="newSelect" class="SELECT box-show-1">
    <div class="listCont customScrollbar">
      <!-- search -->
      <div v-show="search" class="searchCont" @click.stop>
        <div class="cont">
          <!-- icon -->
          <img
            class="icon_search"
            :src="require('@/assets/img/08-Device-check/icon-camera-blue.svg')"
          />
          <input class="input_search" type="text" v-model="searchText" />
        </div>
      </div>
      <!-- list -->
      <div v-if="listData.length" class="list">
        <p
          v-for="(list, index) in listData"
          :key="index"
          class="font_zero fontWeight_medium"
          :class="{ active: activeIndex == index }"
          :style="style_item(align)"
          @click.stop="selectClick(list, index)"
        >
          {{ list.value }}
        </p>
      </div>
      <div v-else class="nodata font_zero">NO DATA</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    listData: {
      type: Array,
      default: () => [],
    },
    activeIndex: {
      type: Number,
      default: () => null,
    },
    align: {
      type: String,
      default: () => "left",
    },
    search: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    // newImg: () => import('@/views/pc/children/components/newImg'),
  },
  data() {
    return {
      searchText: "",
    };
  },
  methods: {
    /** style **/
    style_item(align) {
      let style = {};
      style.textAlign = align;
      return style;
    },
    /** style end **/
    setPostion(event) {
      // console.log(event)
      let window_height = window.innerHeight;
      let window_width = window.innerWidth;
      let mouse_x = event.clientX;
      let mouse_y = event.clientY;

      let select = this.$refs.newSelect;
      select.style.top = `${mouse_y + 10}px`;
      select.style.left = `${mouse_x + -select.clientWidth / 2}px`;

      this.searchText = "";
    },
    selectClick(data, index) {
      this.$emit("selectClick", data, index);
    },
  },
};
</script>

<style scoped lang="scss">
.SELECT {
  position: fixed;
  border-radius: 15px;
  background-color: #ffffff;
  color: #224e96;
  overflow: hidden;
  z-index: 11;
  max-height: 300px;
  .listCont {
    width: 100%;
    height: 100%;
    max-height: 300px;
  }
  .searchCont {
    min-width: 200px;
    height: 50px;
    padding: 8px 10px;
    box-sizing: border-box;
    .cont {
      background-color: #f1f1f0;
      width: 100%;
      height: 100%;
      border-radius: 50px;
      box-sizing: border-box;
      padding: 0 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .icon_search {
        width: 14px;
        height: 14px;
      }
      .input_search {
        background-color: #f1f1f0;
        color: #224e96;
        width: 100%;
      }
    }
  }
  .list {
    min-width: 80px;
    p {
      padding: 8px 20px;
      cursor: pointer;
      border-bottom: 1px solid #f1f1f0;
      &:hover {
        // background-color: #F1F1F0;
        background-color: #2472b3;
        color: #ffffff;
      }
      // &.active{
      //   background-color: #2472B3;
      //   color: #ffffff;
      // }
      &:last-child {
        border-bottom: 0px;
      }
    }
  }
  .nodata {
    padding: 10px;
  }
}
</style>